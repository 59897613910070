import React, { Component } from 'react'
import { withTranslation as translate } from 'react-i18next'
import Layout from './Layout'
import withConfig from '../services/withConfig'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true })

    // Rollbar configured in public/index.html
    if (window.Rollbar) {
      window.Rollbar.error(error)
    } else {
      console.log('errorBoundary:', error)
    }
  }

  render () {
    if (this.state.hasError) {
      const { t, config: { theme } } = this.props

      return (
        <Layout theme={theme}>
          <div className='content error'>
            <h1>{t('error.http500.title')}</h1>
            <p dangerouslySetInnerHTML={{ __html: t('error.http500.body_html') }} />
          </div>
        </Layout>
      )
    }
    return this.props.children
  }
}

export default translate()(withConfig(ErrorBoundary))

import React from 'react'

import staging from './config/staging'
import stagingNorway from './config/staging_norway'
import austria from './config/austria'
import norway from './config/norway'
import finland from './config/finland'
import switzerland from './config/switzerland'
import germany from './config/germany'
import stagingGermany from './config/staging_germany'

const router = {
  // staging
  'signup-ui.staging.credlock.net': staging,
  'signup-ui-no.staging.credlock.net': stagingNorway,
  'signup-ui-fi.staging.credlock.net': finland,
  'signup-ui-at.staging.credlock.net': austria,
  'signup-ui-de.staging.credlock.net': stagingGermany,

  // prod
  'signup-ui.production.credlock.net': austria,

  'signup.evofitness.no': norway,
  'signup.evofitness.at': austria,
  'signup.evofitness.fi': finland,
  'signup.evofitness.ch': switzerland,
  'signup.evofitness.de': germany,

  default: require('./config/default').default
}

// Adds config to props based on provided hostname
const withConfig = (WrappedComponent) => {
  const WithConfig = (props) => {
    const hostname = window.location.hostname
    const config = router[hostname] || router.default
    return <WrappedComponent config={config} {...props} />
  }

  return WithConfig
}

export default withConfig

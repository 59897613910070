import React from 'react'
import { withTranslation as translate } from 'react-i18next'
import withConfig from '../../../services/withConfig'

const NextPayment = ({ payment, config, t, i18n }) => {
  // Always use fr-ch as the locale as that yields date formats conforming with CL2
  const formattedDate = new Date(payment.date).toLocaleDateString('fr-ch')
  const formattedAmount = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: config.currency }).format(payment.total / 100)

  return (
    <p className='next-payment'>
      {/* TODO: i18n interpolation configuration should be global, but not getting that to work this is an ugly workaround */}
      {t('signup.quote.next_payment', { amount: formattedAmount, date: formattedDate, interpolation: { prefix: '%{', suffix: '}' } })}
    </p>
  )
}

export default translate()(withConfig(NextPayment))

import React, { useEffect } from 'react'

import Quote from '../../components/Quote'
import { withTranslation as translate } from 'react-i18next'
import { withQuote } from '../../components/withQuote'
import withConfig from '../../services/withConfig'
import { withRouter } from 'react-router-dom'

// Uses src/services/api/mock/index.js to get Verifone Checkout ID during development
// Requires constant refresh due to expiry policies.
const CreditCard = (props) => {
  const { t } = props

  useEffect(() => {
    const { quote: { paymentMethod: { details: { url: iframeSrc } } } } = props

    const script = document.createElement('script')
    script.src = iframeSrc
    script.defer = true
    document.querySelector('#payment_form_container').appendChild(script)

    return () => {
      const container = document.querySelector('#payment_form_container')
      if (container) {
        container.removeChild(script)
      }
    }
  }, [props])

  // TODO: do not show before Verifone iframe is ready to avoid flicker
  // TODO: error pages from Verifone have been observed to cover quote charges too

  // NOTE: the Verifone iframe _cannot_ live within flex controlled layout!
  return (
    <>
      <h1>{t('signup.credit_card.title')}</h1>
      <div className='credit-card-container'>
        <Quote t={t} />
        <div id='payment_form_container' />
      </div>
    </>
  )
}

export default translate()(withRouter(withConfig(withQuote(CreditCard))))

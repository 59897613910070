import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import withConfig from '../../../services/withConfig'

// Maps system concepts to Phraseapp i18n keys
const systemConceptToI18NMappings = {
  signup_fee: 'signup.quote.signup_fee',
  base_service_rest_of_month_fee: 'signup.quote.base_service_rest_of_month_fee'
}

const CurrentPayment = ({ payment, config, t, i18n }) => {
  const lineRows = payment.lines.map((line, index) => {
    const translationKey = systemConceptToI18NMappings[line.systemConcept]

    return (
      <tr key={index}>
        <td>{t(translationKey)}</td>
        <td>{new Intl.NumberFormat(i18n.language, { style: 'currency', currency: config.currency }).format(line.amount / 100)}</td>
      </tr>
    )
  })

  const totalRow = (
    <tr>
      <td>{t('signup.quote.total')}</td>
      <td>{new Intl.NumberFormat(i18n.language, { style: 'currency', currency: config.currency }).format(payment.total / 100)}</td>
    </tr>
  )

  return (
    <table>
      <caption>{t('signup.quote.title')}</caption>
      <tbody>
        {lineRows}
      </tbody>
      <tfoot>
        {totalRow}
      </tfoot>
    </table>
  )
}

export default translate()(withConfig(CurrentPayment))

import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const Name = ({ firstName, lastName, onChange, t, errors, inputRefs }) => {
  const firstNameError = errors.user && errors.user.firstName
  const lastNameError = errors.user && errors.user.lastName

  return (
    <div className='name'>
      <div>
        <input
          type='text' className={firstNameError ? 'error' : ''}
          ref={(input) => (inputRefs.firstName = input)}
          id='first-name' name='firstName' value={firstName}
          onChange={onChange} placeholder={t('signup.profile.first_name')}
          autoComplete='given-name'
        />
        {firstNameError && <Error messageKey='signup.profile.error.blank' />}
      </div>
      <div>
        <input
          type='text' className={lastNameError ? 'error' : ''}
          ref={(input) => (inputRefs.lastName = input)}
          id='last-name' name='lastName'
          value={lastName}
          onChange={onChange} placeholder={t('signup.profile.last_name')}
          autoComplete='family-name'
        />
        {lastNameError && <Error messageKey='signup.profile.error.blank' />}
      </div>
    </div>
  )
}

export default translate()(Name)

import React from 'react'

import { withTranslation as translate } from 'react-i18next'

// Checkout button is placed outside form tags.
//
// Cannot refer back using form attribute due to
// lacking support in IE. Takes submit handler as prop
// instead.
const CheckoutButton = ({ t, onSubmit }) => (
  <input
    className='next-button'
    id='checkout-button'
    type='submit'
    // form="signup-form"
    onClick={onSubmit}
    value={t('signup.profile.next')}
  />
)

export default translate()(CheckoutButton)

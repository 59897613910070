import { useEffect, useRef } from 'react'
import { WidgetInstance } from 'friendly-challenge'

// Based on https://docs.friendlycaptcha.com/#/widget_api?id=full-example-in-react-with-react-hooks
const Captcha = ({ onDone }) => {
  const container = useRef()
  const widget = useRef()

  const errorCallback = (err) => {
    console.log('There was an error when trying to solve the Captcha.')
    console.log(err)
  }

  const getLocale = () => {
    const value = `; ${document.cookie}`
    const parts = value.split('; locale=')
    if (parts.length === 2) return parts.pop().split(';').shift()?.split('-')[0]
    return null
  }

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
        startMode: 'focus',
        language: getLocale(), // Currently using locale string only. We can also specify all texts individually. See data structure on https://github.com/FriendlyCaptcha/friendly-challenge/blob/master/src/localization.ts#L416
        doneCallback: onDone,
        errorCallback
      })
    }

    return () => {
      if (widget.current !== undefined) widget.current.reset()
    }
    // eslint-disable-next-line
  }, [container])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
      <div
        ref={container}
        className='frc-captcha'
        data-sitekey='FCMQGBDD9CNV4N3D'
        style={{ borderRadius: '0.4em' }}
      />
    </div>
  )
}

export default Captcha

import React from 'react'
import Error from './Error'

const BlockedError = ({ errors, t }) => {
  if (!errors) return null
  if (!errors.user) return null
  if (!errors.user.membership) return null
  if (!errors.user.membership.includes('blocked')) return null

  return (
    <Error messageKey='signup.profile.error.blocked' />
  )
}

export default BlockedError

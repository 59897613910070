const operator = 'c04bc7c3-903f-4804-a952-9abc0016b824'

const germanyConfig = {
  operatorUUID: operator,
  theme: 'evo-hp',
  logo: 'logo.png',
  currency: 'EUR',
  showRegions: false,
  paymentMethods: ['credit_card', 'sepa'],
  mobilePrefix: '+49',
  homeURL: 'https://evofitness.de',
  termsURL: 'https://evofitness.de/agb',
  locales: {
    all: ['de-DE', 'en-DE'],
    defaultLocale: 'de-DE'
  },
  tracking: {
    gtm: {
      id: 'GTM-MMFSVRH'
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  dibs: {
    callbackURL: `https://dibs-api.production.credlock.net/${operator}/signup`
  },
  mypage: {
    url: 'https://me.evofitness.de'
  }
}

export default germanyConfig

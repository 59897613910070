// Convert all incoming JSON object to use camel case keys (recursively)
// Code heavily based on https://stackoverflow.com/questions/12931828/convert-returned-json-object-properties-to-lower-first-camelcase
const toCamelCase = (o) => {
  let newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamelCase(value)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        newKey = origKey.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() })
        value = o[origKey]
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamelCase(value)
        }
        newO[newKey] = value
      }
    }
  }

  return newO
}

export { toCamelCase }

import React from 'react'

const ButtonPanel = ({ children }) => {
  return (
    <div>
      <nav className='button-panel'>
        {children}
      </nav>
    </div>
  )
}

export default ButtonPanel

import React from 'react'

import Error from './Error'

const AcceptTerms = ({ value, onChange, text, errors, inputRefs }) => {
  return (
    <div>
      <label
        htmlFor='acceptTerms'
        ref={(input) => (inputRefs.acceptTerms = input)}
      >
        <input
          type='checkbox'
          name='acceptTerms'
          id='accept-terms'
          onChange={onChange}
          checked={value === 'true' || value === true}
        />
        {text && <span dangerouslySetInnerHTML={{ __html: text }} />}
      </label>
      {errors.user && errors.user.acceptTerms && <Error messageKey='signup.profile.error.blank' />}
    </div>
  )
}

export default AcceptTerms

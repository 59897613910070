import React from 'react'

const QuoteContext = React.createContext(
  {
    uuid: null,
    code: null,
    email: null,
    mobile: null,
    verified: false,
    paymentMethod: null,
    currentPayment: null,
    nextPayment: null,
    presentation: null
  }
)

export default QuoteContext

// Returns URL to DIBS payment page
const dibsURL = (quote, language, config, userMessage = null) => {
  const params = billingServiceParams(quote)

  appendPaymentPageContext(params)
  appendUserNavigation(params)
  appendDIBSToCredlockCallbackURL(params, config)
  appendDIBSTestParameter(params, config)
  appendPromotionText(params, quote)
  appendCurrentPayment(params, quote.currentPayment, language, config.currency)
  appendNextPayment(params, quote.nextPayment, language, config.currency)
  appendUserMessage(params, userMessage)

  // TODO: fix test account name to match DIBS config or remove
  if (params.account === 'DIBS test account') {
    params.account = ''
  }

  return buildURL(baseURL(quote), params)
}

const buildURL = (baseURL, params) => {
  const queryString = Object.keys(params).map(key => {
    return `${key}=${encodeURIComponent(params[key])}`
  }).join('&')

  return `${baseURL}?${queryString}`
}

const baseURL = (quote) => quote.paymentMethod.details.baseUrl

const billingServiceParams = (quote) => quote.paymentMethod.details.params

const appendUserMessage = (params, userMessage) => {
  if (userMessage) {
    params.user_message = userMessage
  }
}

// Required by DIBS payment page to select correct template
const appendPaymentPageContext = (params) => { params.context = 'signup' }

// Supplies URLs for payment page cancel/next buttons
const appendUserNavigation = (params) => {
  params.accepturl = window.location.origin + '/processing-payment'
  params.cancelurl = window.location.origin + '/profile'
}

// System-to-system callback URL invoked on successfull registration of credit
// card on DIBS server
const appendDIBSToCredlockCallbackURL = (params, config) => {
  params.callbackurl = config.dibs.callbackURL
}

// Allows DIBS payment page to use mock backend
const appendDIBSTestParameter = (params, config) => {
  if (config.dibs.test != null) {
    params.test = config.dibs.test
  }
}

const appendPromotionText = (params, quote) => { params.quote_text = quote.presentation }

const appendCurrentPayment = (params, currentPayment, language, currency) => {
  const systemConceptToDIBSParamMappings = {
    signup_fee: 'quote_signup_fee',
    training_fee: 'quote_base_service_rest_of_month_fee'
  }

  currentPayment.lines.forEach(line => {
    const name = systemConceptToDIBSParamMappings[line.systemConcept]
    const value = formatAmount(line.amount, language, currency)

    params[name] = value
  })

  params.quote_fee = formatAmount(currentPayment.total, language, currency)
}

const appendNextPayment = (params, nextPayment, language, currency) => {
  // Always use fr-ch as the locale as that yields date formats conforming with CL2
  params.quote_next_payment_date = new Date(nextPayment.date).toLocaleDateString('fr-ch')

  params.quote_next_payment_amount = formatAmount(nextPayment.total, language, currency)
}

const formatAmount = (amount, language, currency) => {
  return new Intl.NumberFormat(
    language,
    {
      style: 'currency',
      currency
    }
  ).format(amount / 100)
}

export default dibsURL

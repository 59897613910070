import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import API from '../../services/api'
import { withQuote } from '../../components/withQuote'
import withConfig from '../../services/withConfig'
import Form from './components/Form'
import { withDevelopmentData } from './components/withDevelopmentData'
import dibsURL from '../../services/dibsURL'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

class SignupForm extends React.Component {
  constructor (props) {
    super(props)

    this.inputRefs = {}

    this.parsePreset = this.parsePreset.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCaptchaDone = this.handleCaptchaDone.bind(this)

    if (process.env.REACT_APP_ENV === 'development-disabled') {
      this.state = props.developmentData
    } else {
      // Re-load form data from session storage to support back/re-direct from DIBS payment page and reloads
      this.state = {
        errors: {},
        quoteUUID: props.quote.uuid,
        paymentMethods: props.paymentMethods,
        operatorUUID: props.operatorUUID,
        locationUUID: sessionStorage.getItem('locationUUID') || this.parsePreset('location') || '',
        code: sessionStorage.getItem('code') || this.parsePreset('code') || '',
        firstName: sessionStorage.getItem('firstName') || '',
        lastName: sessionStorage.getItem('lastName') || '',
        street: sessionStorage.getItem('street') || '',
        postalCode: sessionStorage.getItem('postalCode') || '',
        city: sessionStorage.getItem('city') || '',
        mobilePrefix: sessionStorage.getItem('mobilePrefix') || props.mobilePrefix,
        mobileNumber: sessionStorage.getItem('mobileNumber') || '',
        birthdateYear: sessionStorage.getItem('birthdateYear') || new Date().getFullYear() - 34,
        birthdateMonth: sessionStorage.getItem('birthdateMonth') || 1,
        birthdateDay: sessionStorage.getItem('birthdateDay') || 1,
        gender: sessionStorage.getItem('gender') || '',
        email: sessionStorage.getItem('email') || '',
        emailConfirmation: sessionStorage.getItem('emailConfirmation') || '',
        password: sessionStorage.getItem('password') || '',
        passwordConfirmation: sessionStorage.getItem('passwordConfirmation') || '',
        paymentMethod: sessionStorage.getItem('paymentMethod') || 'credit_card',
        gdprOptIn: sessionStorage.getItem('gdprOptIn') || '',
        acceptTerms: sessionStorage.getItem('acceptTerms') || '',
        captcha: sessionStorage.getItem('captcha') || '' // TODO: ok to reuse captcha response?
      }
    }
  }

  parsePreset (fieldName) {
    return qs.parse(window.location.search)[`presets[${fieldName}]`]
  }

  // Build a struct to match expected input in memberships#quote
  completeQuote () {
    const { config, i18n } = this.props

    const {
      quoteUUID,
      operatorUUID,
      locationUUID,
      code,
      firstName, lastName,
      street, postalCode, city,
      mobilePrefix, mobileNumber,
      birthdateYear, birthdateMonth, birthdateDay,
      gender,
      email, emailConfirmation,
      password, passwordConfirmation,
      paymentMethod,
      gdprOptIn,
      acceptTerms,
      captcha
    } = this.state

    const params = {
      uuid: quoteUUID,
      operator_id: operatorUUID,
      location_id: locationUUID,
      code,
      user: {
        first_name: firstName,
        last_name: lastName,
        address: {
          street,
          postal_code: postalCode,
          postal_location: city
        },
        mobile: {
          prefix: mobilePrefix,
          number: mobileNumber
        },
        birthdate: `${birthdateYear}-${birthdateMonth}-${birthdateDay}`,
        gender,
        email,
        email_confirmation: emailConfirmation,
        password,
        password_confirmation: passwordConfirmation,
        payment_method: paymentMethod,
        gdpr_opt_in: gdprOptIn,
        accept_terms: acceptTerms,
        locale: i18n.language
      },
      captcha,
      completed: true
    }

    API.completeQuote(params, (quote) => {
      const { paymentMethod: { provider } } = quote

      this.props.quote.updateGlobalQuote({
        // Returned by API
        uuid: quote.uuid,
        currentPayment: quote.currentPayment,
        nextPayment: quote.nextPayment,
        presentation: quote.presentation,
        paymentMethod: quote.paymentMethod,
        requireVerification: quote.requireVerification,

        // Local state in form
        code,
        email,
        mobilePrefix,
        mobileNumber
      })

      // take a local copy of verified to be sure we are handling the correct state
      // as setState is asyncronous
      let verified = this.state.verified
      // If verification is not require, mark as completed
      if (!verified && !quote.requireVerification) {
        verified = !quote.requireVerification
        this.setState({ verified })
      }

      if (!verified) {
        API.requestVerificationCode({
          operator_id: operatorUUID,
          quote_id: quoteUUID,
          domain: window.location.hostname
        }, () => {
          this.props.history.push({ pathname: '/verify-code' })
        })
      } else if (provider === 'dibs') {
        window.location.href = dibsURL(quote, i18n.language, config)
      } else if (provider === 'slimpay') {
        window.location = quote.paymentMethod.details.url
      } else if (provider === 'verifone') {
        // Will forward props so we can access paymentMethod.details.url in credit-card view
        this.props.history.push({
          pathname: '/credit-card'
        })
      } else if (provider === 'invoice') {
        this.props.history.push('/invoice-payment')
      } else {
        throw new Error(`Payment provider ${provider} not supported`)
      }
    }, (errorCode, errors) => {
      this.setState({ processing: false }, () => {
        if (errorCode === 422) {
          this.setState({ errors })
          let errorKey = null
          let errorValue = errors

          // set focus on the first error element - may not be in same order as on screen
          while (typeof errorValue === 'object' && !Array.isArray(errorValue)) {
            errorKey = Object.keys(errorValue)[0]
            errorValue = errorValue[errorKey]
          }
          if (errorKey && this.inputRefs[errorKey]) {
            this.inputRefs[errorKey].focus()
          }
        } else {
          // TODO: Show error msg somewhere
          console.log(errorCode)
          console.log(errors)
        }
      })
    })
  }

  updateQuote () {
    const { quoteUUID, operatorUUID, locationUUID, code, email, mobilePrefix, mobileNumber } = this.state

    // Only send params relevant for quote calculation
    const params = {
      uuid: quoteUUID,
      operator_id: operatorUUID,
      location_id: locationUUID,
      code,
      completed: false
    }

    API.updateQuote(params, (data) => {
      this.props.quote.updateGlobalQuote({
        // Returned by API
        uuid: data.uuid,
        currentPayment: data.currentPayment,
        nextPayment: data.nextPayment,
        presentation: data.presentation,
        paymentMethod: data.paymentMethod,
        requireVerification: data.requireVerification,

        // Local state in form
        code,
        email,
        mobilePrefix,
        mobileNumber
      })

      const codeValidationError = data.validationErrors?.code
      if (codeValidationError) {
        const errors = { ...this.state.errors }
        errors.code = codeValidationError
        this.setState({ errors })
      } else {
        const errors = { ...this.state.errors }
        delete errors.code
        this.setState({ errors })
      }
    })
  }

  // componentDidUpdate() {
  //   console.log('updated:', this.state.errors)
  //   if (this.state.errors) {
  //     let elemId = Object.keys(this.state.errors)[0]
  //     if (elemId) {
  //       elemId = elemId.replace(/[A-Z]/g, c => `-${c.toLowerCase()}`)
  //       console.log('elemId:', elemId)
  //       // const elem = document.querySelector(`input#${elemId}`)
  //       const elem = container //.querySelector(`body`)
  //       // const elem = this.renderRoot //.querySelector('body')
  //       console.log('elem:', elem)
  //       // if (elem) {
  //       //   elem.focus()
  //       // }
  //     }
  //   }
  // }

  componentDidMount () {
    this.updateQuote()
  }

  handleLocationChange (event) {
    this.setState({ locationUUID: event.target.value }, () => {
      sessionStorage.setItem('locationUUID', this.state.locationUUID)

      this.updateQuote()
    })
  }

  handleCodeChange (event) {
    this.setState({ code: event.target.value }, () => {
      sessionStorage.setItem('code', this.state.code)

      this.updateQuote()
    })
  }

  handlePhoneChange (event) {
    const name = event.target.name
    const value = event.target.value

    this.setState({ [name]: value, verified: false }, () => {
      sessionStorage.setItem(name, value)
      this.updateQuote()
    })
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })

    sessionStorage.setItem(name, value)
  }

  handleCaptchaDone (response) {
    this.setState({
      captcha: response
    })
    sessionStorage.setItem('captcha', response)
    console.log('Captured captcha response:', response)
  }

  handleSubmit (event) {
    // if the form has any local validations, run those first and report on them
    // before sumbitting to completeQuote
    if (event.target.form && !event.target.form.checkValidity()) {
      event.target.form.reportValidity()
      return
    }
    event.preventDefault()
    this.setState({ processing: true }, () => {
      this.completeQuote()
    })
  }

  // Store form data in local storage to survive refresh and navigation
  // back from DIBS page using SimpleStorage
  render () {
    return (
      <>
        <Form
          onSubmit={this.handleSubmit}
          onLocationChange={this.handleLocationChange}
          onCodeChange={this.handleCodeChange}
          onPhoneChange={this.handlePhoneChange}
          onCaptchaDone={this.handleCaptchaDone}
          onInputChange={this.handleInputChange}
          requireVerification={this.props.quote.requireVerification}
          inputRefs={this.inputRefs}
          {...this.state}
        />
      </>
    )
  }
}

export default withRouter(translate()(withConfig(withQuote(withDevelopmentData(SignupForm)))))

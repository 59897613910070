import React from 'react'

const TextButton = ({ onClick, text, align, size }) => {
  return (
    <div className={align}>
      <button type='button' className={'text-button ' + size} onClick={onClick}>{text}</button>
    </div>
  )
}

export default TextButton

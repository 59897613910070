import React from 'react'
import { withTranslation as translate } from 'react-i18next'
import QuoteContext from '../../components/Quote/context'
import withConfig from '../../services/withConfig'
import MyPageLink from './components/MyPageLink'
import WelcomeImage from './components/WelcomeImage'
import AdtractionScript from './components/AdtractionScript'

const Welcome = ({ t, config }) => {
  const token = sessionStorage.getItem('mypage-token')

  sessionStorage.clear()

  // Due to component being rendered multiple times we keep the token in session storage
  // to ensure the mypage button works correctly.
  //
  // The token will either expire or die with the browser session.
  sessionStorage.setItem('mypage-token', token)

  return (
    <div className='welcome'>
      <section id='picture'>
        <WelcomeImage theme={config.theme} />
      </section>
      <main>
        <h1>{t('signup.welcome.header_top')}</h1>
        <h2 dangerouslySetInnerHTML={{ __html: t('signup.welcome.header_bottom') }} />

        <section id='sms'>
          <img src='/sms.svg' alt='' />
          <p dangerouslySetInnerHTML={{ __html: t('signup.welcome.pin_code_info') }} />
        </section>

        <p dangerouslySetInnerHTML={{ __html: t('signup.welcome.chip_info') }} />

        <MyPageLink token={token} />
      </main>
      <QuoteContext.Consumer>
        {(quote) => <AdtractionScript config={config} quote={quote} />}
      </QuoteContext.Consumer>
    </div>
  )
}

export default translate()(withConfig(Welcome))

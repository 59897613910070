import React from 'react'
import withConfig from '../../../services/withConfig'

import { withTranslation as translate } from 'react-i18next'

const MyPageLink = ({ t, config, token }) => (
  <>
    <div>{t('signup.welcome.more_info')}</div>
    <a
      href={config.mypage.url + '?access_token=' + token}
      className='next-button'
    >
      {t('signup.welcome.my_evo')}
    </a>
  </>
)

export default translate()(withConfig(MyPageLink))

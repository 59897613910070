import React from 'react'

const WelcomeImage = ({ theme }) => {
  return (
    <picture>
      <source srcSet={`/themes/${theme}/welcome-mobile.jpg`} media='(max-width: 800px)' />
      <source srcSet={`/themes/${theme}/welcome-desktop.jpg`} />
      <img src={`/themes/${theme}/welcome-desktop.jpg`} alt='' />
    </picture>
  )
}

export default WelcomeImage

import './polyfills'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import CurrentLocale from './components/CurrentLocale'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <CurrentLocale>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CurrentLocale>
)

import React from 'react'

const withDevelopmentData = WrappedComponent => {
  const WithDevelopmentData = props => {
    const developmentData = {
      errors: {},
      paymentMethods: props.paymentMethods,
      operatorUUID: props.operatorUUID,
      locationUUID: '11cdcf9a-b620-4076-bb51-68b7d29d5aad',
      firstName: 'Elvis',
      lastName: 'Presley',
      street: 'Elvis Presley Blvd',
      postalCode: 'TN 38116',
      mobilePrefix: '+1',
      mobileNumber: '9013323322',
      birthdateYear: 1935,
      birthdateMonth: 1,
      birthdateDay: 8,
      gender: 'male',
      email: 'elvis@presley.com',
      emailConfirmation: 'elvis@presley.com',
      password: 'secret',
      paymentMethod: 'invoice',
      gdprOptIn: false,
      acceptTerms: false
    }

    return (
      <WrappedComponent developmentData={developmentData} {...props} />
    )
  }

  return WithDevelopmentData
}

export { withDevelopmentData }

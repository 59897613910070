import React from 'react'
import { Redirect } from 'react-router-dom'
import ActivityIndicator from '../../components/ActivityIndicator'
import API from '../../services/api'
import { withTranslation as translate } from 'react-i18next'

class ProcessingPayment extends React.Component {
  constructor () {
    super()

    this.state = {
      status: undefined,
      method: undefined
    }
  }

  componentDidMount () {
    let { operatorUUID, quoteUUID, paymentMethod } = this.props

    // Useful in development only
    if (!operatorUUID || !quoteUUID || !paymentMethod) {
      // return false // return false to _stay_ on processing page forever
      if (!operatorUUID) {
        operatorUUID = '984f763e-1fa0-4581-b088-7d2ea5ac13e3'
      }
      if (!quoteUUID) {
        quoteUUID = '778042d5-2e2d-410b-8dc5-3e1edd493535'
      }
      if (!paymentMethod) {
        paymentMethod = {
          type: 'credit_card',
          provider: 'verifone'
        }
      }
    }

    const params = {
      quote_id: quoteUUID,
      operator_id: operatorUUID,
      payment_method: paymentMethod
    }

    if (paymentMethod.type === 'sepa') {
      paymentMethod.provider_details = { name: 'slimpay', quote_id: quoteUUID }
      paymentMethod.provider_reference = 'required-by-billing'
    }

    // Well, well, well - time to clean up billing interface?
    if (paymentMethod.provider === 'verifone') {
      const searchParams = new URLSearchParams(window.location.search)

      paymentMethod.provider_reference = 'required-by-billing'
      paymentMethod.provider_details = {
        checkout_id: searchParams.get('checkout_id'),
        transaction_id: searchParams.get('transaction_id')
      }
      if (searchParams.has('error_code')) {
        this.setState({ status: 'error', response_code: searchParams.get('error_code') })
      }
    }

    if (paymentMethod.type === 'sepa' || paymentMethod.type === 'invoice') {
      if (typeof this.state.status === 'undefined') {
        API.makeSignupPayment(params)
      }
    }

    // For all: check payment status on regular interval
    this.interval = setInterval(() => {
      API.getQuote(params, (data) => {
        if (data.status === 'open') return

        /*
         * Implementation using top level App component for storage of
         * mypage token triggered re-render (with multiple calls
         * to componentDidMount on distinct components) causing
         * eg. makeSignupPayments to be called multiple times.
         *
         * To enable using local state to avoid multiple calls
         * (i.e. status), using sessionStorage for mypage
         * token is introduced. The token is picked up on the
         * welcome page and the session is cleared.
         *
         * Note: must happen _before_ setState is called as Welcome#render will pickup mypage-token.
         */
        if (data.mypageAccessKey) {
          sessionStorage.setItem('mypage-token', data.mypageAccessKey)
        }

        this.setState({
          status: data.status,
          method: paymentMethod.type
        })
      }, (errorCode, errors) => {
        this.setState({ processing: false }, () => {
          if (errorCode === 422) {
            this.setState({ errors })
          } else {
            // TODO: Show error msg somewhere
            console.log(errorCode)
            console.log(errors)
          }
        })
      })
    }, 2000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    if (this.state.status === 'payment_accepted') {
      return <Redirect to='/welcome' />
    } else if (this.state.method === 'sepa' && this.state.status === 'processing_payment') {
      return <Redirect to='/welcome' />
    } else if (this.state.status === 'payment_declined') {
      return <Redirect to='/?status=declined' />
    } else if (this.state.status === 'error') {
      return <Redirect to='/?status=error' />
    } else {
      return (
        <div className='content processing'>
          <section className='main'>
            <ActivityIndicator i18nKey='signup.payment_processing.title' />
          </section>
        </div>
      )
    }
  }
}

export default translate()(ProcessingPayment)

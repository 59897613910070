import React from 'react'

import { withTranslation as translate } from 'react-i18next'

const ActivityIndicator = ({ t, i18nKey }) => {
  return (
    <>
      <h1>{t(i18nKey)}</h1>
      <img src='/activity-indicator.gif' className='progress-indicator' alt='progress-indicator' />
    </>
  )
}

export default translate()(ActivityIndicator)

import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import withConfig from './../services/withConfig'

const LocaleSwitcher = (props) => {
  // Only render if we have more than 1 available locale in operator config
  if (props.config.locales.all.length < 2) {
    return null
  }

  // We're storing the current locale in the i18next instance
  const currentLocale = props.i18n.language

  // Handler for changing locale fetching the target locale from ID of link
  const onChangeLocale = (event) => {
    event.preventDefault()
    props.i18n.changeLocale(event.target.getAttribute('id'))
  }

  // List available locales fetched from config
  return (
    <ul className='locale-switcher'>
      {props.config.locales.all.map(locale => {
        const displayLocale = locale.split('-')[0]
        const classNames = 'locale ' + (locale === currentLocale ? 'active' : '')

        return (
          <li key={locale} className={classNames}>
            <span className='as-link' id={locale} onClick={onChangeLocale}>{
              displayLocale
            }
            </span>
          </li>
        )
      })}
    </ul>
  )
}

export default translate()(withConfig(LocaleSwitcher))

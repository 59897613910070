import React from 'react'
import i18n from 'i18next'
import XHR from 'i18next-http-backend'
import qs from 'query-string'
import Cookies from 'js-cookie'
import { I18nextProvider } from 'react-i18next'
import withConfig from '../services/withConfig'

// Configure i18next
i18n
  .use(XHR)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}.json'
    },
    react: {
      useSuspense: false
    }
  })

// Wrap i18next adding function for changing locale in all relevant places
const wrappedI18n = () => {
  i18n.changeLocale = function (locale) {
    // Ensable user test in staging with multiple operators with
    // domains such as signup-ui-no.staging.credlock.net
    const parts = window.location.hostname.endsWith('.staging.credlock.net') ? 4 : 2
    const domain = window.location.hostname.split('.').slice(-parts).join('.')

    this.changeLanguage(locale)
    Cookies.set('locale', locale, { expires: 1, domain })

    if (locale !== 'nb-NO' && locale !== 'fi' && locale !== 'dev') {
      Cookies.set('_icl_current_language', locale.split('-')[0], { expires: 1, domain })
    }
  }

  return i18n
}

// Allow external web sites to set the locale.
// Expects parameter value to be the locale short form i.e. de, fi, en etc
const requestParamLocale = (locales) => {
  const locale = qs.parse(window.location.search).language

  const foundLocale = locales.find(l => l.substring(0, 2) === locale)

  return foundLocale || locale
}

// Reads a very specific cookie value set by HP Wordpress sites
//
// Disabled when app is delivered from .net domains as it yields incorrect locales.
const wordpressCookieLocale = (locales) => {
  const locale = Cookies.get('_icl_current_language')
  const topLevelDomain = window.location.hostname.split('.').slice(-1)[0]

  const foundLocale = locales.find(l => l.substring(0, 2) === locale)

  if (foundLocale) return foundLocale

  return locale && topLevelDomain !== 'net' ? `${locale}-${topLevelDomain.toUpperCase()}` : null
}

// Store locale to be picked up on return from external DIBS payment page
const credlockCookieLocale = () => {
  return Cookies.get('locale')
}

const CurrentLocale = ({ config: { locales: { all, defaultLocale } }, children }) => {
  const i18n = wrappedI18n()

  // Set current locale taking multiple sources into account
  i18n.changeLocale(
    requestParamLocale(all) ||
    wordpressCookieLocale(all) ||
    credlockCookieLocale() ||
    defaultLocale
  )

  // Provide current locale to app
  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  )
}

export default withConfig(CurrentLocale)

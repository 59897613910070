import React from 'react'

const FormGroup = ({ title, id, children }) => {
  return (
    <div className='form-group' id={id}>
      {title != null && <h2>{title}</h2>}
      {children}
    </div>
  )
}

export default FormGroup

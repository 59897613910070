import React from 'react'

const FormDiv = ({ title, id, children }) => {
  return (
    <div className='form-group' id={id}>
      {title != null && <h3>{title}</h3>}
      {children}
    </div>
  )
}

export default FormDiv

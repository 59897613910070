import React from 'react'

import md5 from 'js-md5'

// Tracks signups including current transaction details
class AdtractionScript extends React.Component {
  componentDidMount () {
    const { config: { tracking: { adtraction: { c, tp, ap } } } } = this.props

    if (c && tp && ap) {
      const { quote: { uuid, amount, code, email } } = this.props

      // Adtraction wants money formatted as e.g. EUR 99.99. Credlock natively uses Integers (and cents)
      const formattedAmount = (parseFloat(amount) / 10).toFixed(2)

      let md5Digest = null
      if (email) {
        md5Digest = md5.hex(email.trim().toLowerCase())
      }

      const script = document.createElement('script')
      script.src = `https://track.adtraction.com/t/t?t=3&tk=1&am=${formattedAmount}&c=${c}&ti=${uuid}&tp=${tp}&trt=2&cpn=${code}&xd=${md5Digest}&ap=${ap}`
      script.async = true

      document.body.appendChild(script)
    }
  }

  render () {
    return null
  }
}

export default AdtractionScript

import React from 'react'
import { withTranslation as translate } from 'react-i18next'
import Quote from '../../components/Quote'
import ButtonPanel from '../../components/ButtonPanel'
import QuoteContext from '../../components/Quote/context'

const InvoicePayment = ({ t }) => {
  return (
    <QuoteContext.Consumer>
      {quote => (
        <div className='content'>
          <section className='main'>
            <p>
              {t('signup.payment.credit_card_not_required.body_html')}
            </p>
            <div>
              {quote.presentation && <p>{quote.presentation}</p>}
            </div>
            <ButtonPanel>
              <a href='/profile' className='back-button'>
                {t('signup.payment.credit_card_not_required.back')}
              </a>
              <a href='/processing-payment' className='next-button'>
                {t('signup.payment.credit_card_not_required.next')}
              </a>
            </ButtonPanel>
          </section>
          <Quote />
        </div>
      )}
    </QuoteContext.Consumer>
  )
}

export default translate()(InvoicePayment)

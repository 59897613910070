import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const PaymentMethod = ({ value, onChange, t, errors }) => {
  const paymentMethodError = errors.user && errors.user.paymentMethod

  return (
    <div className='payment-method'>
      <div>
        <label htmlFor='creditCard'>
          <input
            type='radio' name='paymentMethod' id='creditCard' value='credit_card'
            checked={value === 'credit_card'} onChange={onChange}
          />
          {t('signup.profile.credit_card')}
        </label>
      </div>
      <div>
        <label htmlFor='SEPA'>
          <input
            type='radio' name='paymentMethod' id='SEPA' value='sepa'
            checked={value === 'sepa'} onChange={onChange}
          />
          {t('signup.profile.direct_debit')}
        </label>
      </div>
      {paymentMethodError && <Error messageKey='signup.profile.error.blank' />}
    </div>
  )
}

export default translate()(PaymentMethod)

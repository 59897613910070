import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import Error from './Error'

const GDPR = ({ value, onChange, t, errors, inputRefs }) => (
  <div id='gdpr-opt-in'>
    <p dangerouslySetInnerHTML={{ __html: t('signup.profile.gdpr.body_html') }} />
    <div>
      <label
        htmlFor='optIn'
        ref={(input) => (inputRefs.gdprOptIn = input)}
      >
        <input
          type='radio' name='gdprOptIn' id='optIn' value='true'
          checked={value === 'true'} onChange={onChange}
        />
        <span dangerouslySetInnerHTML={{ __html: t('signup.profile.gdpr.accept_html') }} />
      </label>
    </div>
    <div>
      <label htmlFor='optOut'>
        <input
          type='radio' name='gdprOptIn' id='optOut' value='false'
          checked={value === 'false'} onChange={onChange}
        />
        <span dangerouslySetInnerHTML={{ __html: t('signup.profile.gdpr.decline_html') }} />
      </label>
    </div>
    {errors.user && errors.user.gdprOptIn && <Error messageKey='signup.profile.error.blank' />}
  </div>
)

export default translate()(GDPR)
